import React, {Component} from "react";

class Cocktail extends Component {
  render() {
    return (
      <div>
        <img src={("/images/1276_Cocktails-4.png")} alt=""/>
        <img src={("/images/map-famous-cocktail.jpg")} alt=""/>
        <img src={("/images/filmlit.jpg")} alt=""/>    
      </div>
    );
  }
}

export default Cocktail;