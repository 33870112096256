import React, {Component} from "react";

class Restaurants extends Component {
  render() {
    return(
      <div>
        <h2>Portland&nbsp;(mostly) restaurants in order, based on quality, value, service, and ambience (number of visits in parentheses).</h2>

        <h4><del>closed</del><br></br>*-especially loud</h4>

        <h3>Lunch/Dinner</h3>

          <p><b>5 stars</b>: <del>Simpatica*</del>, Laurelhurst Market, 3&nbsp;Doors Down Café</p>

          <p><b>4½ stars</b>: Master Kong, Ha&nbsp;& Vl, <del>Esparza’s</del>, Tortilleria Y Tienda De Leon, Chin's Kitchen, Horse Brass Pub, Bollywood Theater, ClarkLewis, Storrs Smokehouse&nbsp;(Newberg), Stammtisch, Sivalai Thai Restaurant, <del>Cafe Castagna</del>, <del>Vindalho</del>, Apizza Scholls, <del>Pix/Bar Vivant</del>, <del>Fado Portuguese Kitchen&nbsp;& Bar</del>, <del>Raven&nbsp;& Rose</del>, Gresham Burrito Shop/Wood Village Burrito Shop</p>

          <p><b>4 stars</b>: My Brother’s Crawfish, VN Fusion Bar&nbsp;& Grill, Tamale Boy, East Side Delicatessen, <del>Foster Burger</del>, Reverend's BBQ, <del>Feastworks</del>, <del>Imperial</del>, Cibo, <del>Chiang Mai</del>, <del>Pok Pok</del>, <del>Vincentes</del>, <del>Accanto</del>, Redwood, <del>Old Salt Marketplace</del>, Santo Domingo Taqueria, Lardo, DeNicola's, Kachka*, Noble Rot, Taqueria Portland, Ruth's Chris, Chepes, Church, Red Star Tavern, Karam, <del>Skyline Burgers&nbsp;(Broadway)</del>, Grain and Gristle, Sayler's Old Country Kitchen, Taqueria Los Gorditos, Pho Corner, Xico, <del>2nd Story</del>, Woodsman Tavern, <del>Sen Yai</del>, <del>Pho Van&nbsp;(Hawthorne)</del>, Morton's the Steakhouse, Oaks Bottom Public House, Gyro House Mediterranean Grill</p>

          <p><b>3 ½ stars</b>: Ixtapa Mexican Restaurant, Rose&nbsp;& Thistle, Gold Dust Meridian, Hoda’s Middle-Eastern Cuisine, Meeka Japanese Restaurant, <del>Iorio</del>, Picnic House, Bit House Saloon, <del>Biwa Izakaya*</del>, Juan Colorado, Mike's Drive-In, La Sirenita, <del>Bywater Grocery</del>, <del>Noraneko</del>, <del>Ringside Fish House</del>, <del>Bar Dobre</del>, Kenny&nbsp;& Zuke’s Delicatessen, La Fondita&nbsp;(Salem), <del>The Goose</del>, Hale Pele, Grassa, Ate-Oh-Ate, Double Mountain Taproom, La Tia Juana Taqueria, Taqueria Rico Taco, Try me Ethiopian Cuisine, Sapphire Hotel, <del>Roost</del>, <del>Lucky Strike</del>, <del>Oso</del>, <del>Tapalaya*</del>, Sugarpine Drive-In, <del>Tabla</del>, Olympic Provisions&nbsp;(Eastside), Taqueria La Mestiza, Teote, El Cubo de Cuba, <del>Bazi Bierbrasserie</del>, El Sombrero Tapatio, <del>Uno Mas</del>, <del>Club 21</del>, Two Brothers Café&nbsp;& Grill, Five Guys, Don Kamaron, Clyde's Prime Rib Restaurant & Bar, Nicholas</p>

          <p><b>3 stars</b>: Franks a Lot, Cha! Cha! Cha!, Gather Food&nbsp;& Drink, Fogo de Chao, Pollo Norte, <del>La Panza Cafe</del>, <del>Mama Leo’s</del>, St. Jack, <del>Yen Ha</del>, The Lamp, Hot Lips Pizza, Sizzle Pie, Tambayan, Southpark Seafood, Salvador Molly’s, Double Dragon, Fujiyama Sushi and Grill, HopCity Tavern, The Ranger Station, Dove Vivi, Dan and Louis Oyster Bar, Hawthorne Hophouse, <del>Branch Whiskey Bar</del>, <del>SubRosa</del>, <del>Kenny&nbsp;& Zuke’s Deli Bar</del>, <del>The Brooklyn House</del>, Laurelwood Brew Pub, Hopworks Urban Brewery, <del>Slowburger</del>, Delta Café, <del>Knuckle Sandwich</del>, Double Barrel, Migration Brewing, Nick's Famous Coney Island, Cuon&nbsp;-&nbsp;Vietnamese Street Food, <del>Blackbird Pizza</del>, <del>People's Pig (Burnside)</del>, <del>Smokehouse Tavern</del></p>

        <h3>Breakfast/Brunch&nbsp;(in order)</h3>

          <p><del>Simpatica*</del>, Screen Door, <del>Country Cat</del>, Beach Dog Cafe&nbsp;(Lincoln City), Pepper Box, Pine State Biscuits, Zell’s, Gateway Breakfast House, <del>Tapalaya</del>, <del>Muscadine</del>, Redwood, Jam, Petite Provence, Gravy, St.&nbsp;Honoré Bakery, Midpoint Food & Drink, Genie's Too, Block House Cafe&nbsp;(Dayton), Bread and Ink Cafe, Breakfast At Valerie's&nbsp;(Vancouver), Black Bear Diner, Stepping Stone Cafe, <del>Anchor End Eurocafe and Bakery</del>, Biscuits Cafe, The Waffle Window, <del>Chez Machin</del>, Ya Hala, Cheryl's on 12th, Genie's, Mehri's Cafe and Bakery, McCrae's Country Cafe, Sweet Betty's Bistro, Cadillac Cafe, <del>Off the Waffle</del>, Brick and Mortar&nbsp;(Albany), Black Rabbit Restaurant & Bar, Kings Omelets, <del>Utopia Café</del>, <del>Kerns Kitchen</del>, <del>Bridges Café</del>, <del>Blue Pig Café</del>, <del>Sanborn's</del>, Hazel Room, Doug Fir, Cricket Cafe, Swift Lounge&nbsp;(no longer serve brunch), Slappy Cakes, <del>Arleta Library Bakery&nbsp;& Cafe</del>, City State Diner, Sckavone’s, Tabor Bread, New Deal Cafe, Tom's Restaurant, <del>Cup and Saucer</del>, Bottle&nbsp;+ Kitchen, <del>Le Bistro Montage</del>, Firehouse Grill&nbsp;(Seaside), Cameo Café, Junior's Cafe, Heidi's, Milo's City Cafe, Holman's</p>

        <h3>Favorite Food Carts&nbsp;(alpha order, at least two visits)</h3>

          <p>808&nbsp;Grinds, Azul Tequila Mexican Taqueria, <del>Baghdad Iraqi Grill</del>, Birrieria La Plaza, <del>Blues City Biscuits</del>, Brazilian House, <i>Brunchbox&nbsp;(now brick&nbsp;& mortar)</i>, <del>Burger Guild</del>, Dinner Bell Barbecue, <i>El Capullo Taco Trailer&nbsp;(now brick&nbsp;& mortar)</i>, Euro Dish, <i>Flavours of India&nbsp;(now brick&nbsp;& mortar)</i>, <i>Fried Egg I'm In Love&nbsp;(now brick&nbsp;& mortar)</i>, Frying Scotsman, Fuego, <i>Guero&nbsp;(now brick&nbsp;& mortar)</i>, Grill on the Go, <i>Hapa PDX&nbsp;(now brick&nbsp;& mortar)</i>, <del>Huong's Vietnamese Food</del>, <del>Italian Market</del>, Kim Jong Grillin', Korean Twist, La Jarochita, La Morenita, <i>New Taste of India&nbsp;(now brick&nbsp;& mortar)</i>, <i>Nong's Khao Man Gai&nbsp;(now brick&nbsp;& mortar)</i>, <del>Number One Bento/Taste of Korea&nbsp;(sister carts)</del>, <del>Original Schnitzelwich/Tabor</del>, <i>Pastrami Zombie&nbsp;(now brick&nbsp;& mortar as "Sammich")</i>, <i>People’s Pig&nbsp;(now brick&nbsp;& mortar)</i>, Poblano Pepper, Saigon Kitchen, Taqueria Antojitos Yucateco, Taste of Poland, <i>Tito’s Burritos&nbsp;(now brick&nbsp;& mortar)</i>, Viking Soul Food</p>

        <h3>Dining Fouls</h3>
        
          <p>Cafe Broder*&nbsp;(loud, long wait for overpriced&nbsp;($50 for two adults and one child) breakfast)</p>
          <p><del>Cricket Cafe&nbsp;(pre-2018 ownership)</del>&nbsp;(burnt one side of pancakes and served burnt side down instead of redoing)</p>
          <p>EastBurn&nbsp;(large amount of unwanted onions and garlic not
          mentioned in menu item descriptions)</p>
          <p><del>Fressen Artisan Bakery</del>&nbsp;(unapologetically bad service including a 40-minute wait for trivial amount of spaetzle)</p>
          <p>Frank's Noodle House&nbsp;(loud and one-dimensionally seasoned dishes)</p>
          <p><del>Lotus Cardroom and Café</del>&nbsp;(inedible salty demi-glace on dinner
          entrée)</p>
          <p>NEPO&nbsp;42&nbsp;(understaffed and overwhelmed lunch service)</p>
          <p><del>Portland Prime</del>&nbsp;(sad attempt at a steak house; supermarket-like cheese plate)</p>
          <p>Suzette Creperie&nbsp;(indolent service)</p>
          <p>Tabor Tavern&nbsp;(after long wait, waffle and $13 burger both arrived overdone/charred)</p>
          <p><del>Trigger*</del>&nbsp;(DEAFENING, overpriced limited menu, wouldn’t modify desired item)</p>

      </div>
    );
  }
}

export default Restaurants; 